/* Add this CSS to your existing stylesheet or create a new one */

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.select {
    border: none;
    background: none;
    outline: none;
    width: 90%;
}
.cutomRow:hover .select {
    background: rgb(0, 66, 104);
    color: white;
}
.order_link{
    color: red;
    text-decoration: none;
}

.order_link:visited{
    color: rgb(0, 0, 255);
}

.cutomRow,
.cutomRowHeader {
    display: grid;
    grid-template-columns:5% 7% 15% 11% 20% 16% 15% auto;
    grid-gap: 1px;
    margin: 15px 0;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.327);
    background: rgb(255, 255, 255);
    /* border-radius: 20px; */

}

.cutomHeader {
    background: rgb(0, 66, 104);
    color: white;
    font-weight: bold;
}

.cutomRow:hover {

    background: rgb(0, 66, 104);
    color: white;
}

.cutomRow:hover a:hover,
.cutomRow:hover a {
    color: white;
}

.cutomRow:hover .cutomRowData {
    border: 0.5px solid rgb(255, 255, 255);

}
.cutomRowData{
    word-wrap: break-word;
}
.cutomRowData,
.cutomHeader {
    padding: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.172);

}

/* .cutomHeader:last-child,
.cutomRowData:last-child {
    border-radius: 0px 20px 20px 0;
}

.cutomHeader:first-child,
.cutomRowData:first-child {
    border-radius: 20px 0 0 20px;
} */


@media screen and (max-width : 768px) {
    .CustomTable2nd {
        overflow: scroll;
        padding: 5px;
    }

    .cutomRow,
    .cutomRowHeader {
        grid-template-columns: 153px 153px 153px 153px 153px;
        width: 768px;

    }
}