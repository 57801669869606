

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #111;
  }
  
  .loginBox {
    background-color: #222;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: glowing 3s infinite;
  }
  
  .loginBox h2 {
    color: #fff;
    margin-bottom: 20px;
  }
  
  .formGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .formGroup .icon {
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
  }
  
  .inputField {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    padding: 10px 20px;
    color: #fff;
    border-radius: 4px;
    width: 300px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .inputField:focus {
    border-color: #007bff;
    box-shadow: 0 0 10px 1px rgba(0, 123, 255, 0.5);
  }
  
  .submitButton {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  .glowing {
    animation: glowings 3s infinite; 
    box-shadow: 0px 0px 50px 1px rgb(0, 44, 139);
  } 
  /* @keyframes glowings {
    0% {
        box-shadow: 10px 5px 50px 1px rgb(255, 255, 255);
    }
    50% {
        box-shadow: 10px 5px 50px 1px rgb(0, 44, 139);

    }
    100% {
        box-shadow: 10px 5px 50px 1px rgb(155, 0, 0);

    }
  }
   */



/*   
  .glowing {
    /* animation: glowing 3s infinite; *//*   
    box-shadow: 0 0 16px  rgba(0, 255, 55, 0.508);
  } */
  
  /* @keyframes glowing {
    0% {
      box-shadow: 0 0 8px rgb(0, 255, 55);
    }
    50% {
      box-shadow: 0 0 12px rgba(0, 45, 71, 0.6);
    }
    100% {
      box-shadow: 0 0 8px rgb(0, 32, 86);
    }
  } */
  