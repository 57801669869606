.formMaoin {
    width: 50% ;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .formMaoin {
        width: 95% ;
    }
    
}