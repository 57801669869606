.alert {
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 20;
    width: 40%;
    margin: 0 30%;
}

@media screen and (max-width: 768px) {
    .alert {
        width: 70%;
        margin: 0 15%;
        top: 70px;
        font-size: 12px;
    }
}